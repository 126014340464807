<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row class="p-3">
      <b-col md="4" lg="3" class="text-center">
        <img style="width: 150px;" src="../../assets/img/avatar.png" alt="admin@bootstrapmaster.com"
          class="avatar img avatar-xl img-avatar rounded" />
      </b-col>
      <b-col class="text-center text-md-left">
        <ul class="list-unstyled">
          <li>
            <span class="font-weight-bold">
              <i class="fa fa-user"></i> Username :
            </span>
            {{ this.username }}
          </li>
          <li>
            <b-row class="p-3 flex-nowrap align-items-baseline justify-content-start">
              <div class="font-weight-bold mr-1">
                <i class="fa fa-envelope"></i> Email :
              </div>
              <span class="mr-1" v-if="!editMode">{{ this.email }}</span>
              <span v-else>
                <input type="email" v-model="editedEmail" class="form-control"/>
                <button @click="saveEmail" class="btn btn-primary mt-2">Save</button>
              </span>
              <span>
                <i @click="toggleEditMode" :class="['fa', editMode ? 'fa-close' : 'fa-pencil', 'ml-2', 'cursor-pointer']"></i>
              </span>
            </b-row>           
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'generalInformationProfile',
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('session_auth')),
      editMode: false,
      editedEmail: '',
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.profile.isLoading,
      success: (state) => state.profile.success,
      message: (state) => state.profile.successMessage,
      username: (state) => state.profile.username,
      email: (state) => state.profile.email,
    }),
  },
  created() {
    this.getProfile();
  },
  methods: {
    ...mapActions("profile", ["fetchProfile","changeEmail"]),
    getProfile() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchProfile(payload);
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      this.editedEmail = this.email;
    },
    saveEmail() {
    const payload =  {
        "email" : this.email,
        "new_email" : this.editedEmail
    }
    this.changeEmail(payload).then(() => {
          this.getProfile();
          this.editMode = false;
        }).catch(() => {
        });
    }
  },
}
</script>