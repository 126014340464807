<template>
  <b-form @submit="onSubmit">
    <b-row class="m-3">
      <b-col lg="4">
        <div class="input-group">
          <input v-if="showPassword" type="text" class="form-control" id="inlineFormInputGroup" placeholder="new password" v-model="form.password" :state="statePassword(form.password)" min="8">
          <input v-else type="password" class="form-control" id="inlineFormInputGroup" placeholder="new password" v-model="form.password" :state="statePassword(form.password)" min="8">
          <div class="input-group-prepend">
            <div class="input-group-text" @click="toggleShow">
              <i :class="{ 'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword }"></i>
            </div>
          </div>
        </div>
        <small class="text-danger mt-1" v-if="form.password && !statePassword(form.password)">*minimum 8 character</small>
        <br>
        <br>
        <div class="input-group">
          <input v-if="showPasswordConfirm" type="text" class="form-control" id="inlineFormInputGroup" placeholder="confirm password" v-model="form.confirmPassword" :state="stateConfirmPassword(form.password, form.confirmPassword)">
          <input v-else type="password" class="form-control" id="inlineFormInputGroup" placeholder="confirm password" v-model="form.confirmPassword" :state="stateConfirmPassword(form.password, form.confirmPassword)">
          <div class="input-group-prepend">
            <div class="input-group-text" @click="toggleShowConfirm">
              <i :class="{ 'fa fa-eye': showPasswordConfirm, 'fa fa-eye-slash': !showPasswordConfirm }"></i>
            </div>
          </div>
        </div>
        <small class="text-danger mt-1" v-if="form.confirmPassword && !stateConfirmPassword(form.password, form.confirmPassword)">*passwords do not match</small>
        <br> <!-- New line -->
        <b-button type="submit" variant="primary" block :disabled="isDisabled">
          Change Password
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'changePasswordUserProfile',
  data() {
    return {
      form: {
        password: null,
        confirmPassword: null,
      },
      showPassword: false,
      showPasswordConfirm: false,
    }
  },
  watch: {
    errorMessage(data) {
      if (!data) return;
      this.$swal({
        icon: 'error',
        title: data,
      });
    },
    successMessage(data) {
      if (!data) return;
      this.$swal({
        icon: 'success',
        title: data,
      }).then(() => {
        location.reload()
      });
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.profile.isLoading,
      errorMessage: (state) => state.profile.errorMessage,
      successMessage: (state) => state.profile.successMessage,
      username: (state) => state.profile.username,
      email: (state) => state.profile.email,
    }),

    isDisabled(){
      return !(this.form.password == this.form.confirmPassword && this.form.password && this.form.confirmPassword && this.form.password?.length >= 8);
    }
  },
  methods: {
    ...mapActions('profile', ['changePassword']),
    onSubmit(e) {
      e.preventDefault();
      console.log(this.form);
      this.changePassword(this.form);
    },
    statePassword(password) {
      if (!password) return null;
      if (password?.length < 8) return false;
      return true;
    },
    stateConfirmPassword(password, confirmPassword) {
      if (!confirmPassword) return null;
      if (password?.length < 8) return false;
      if (password !== confirmPassword) return false;
      return true;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;
    },
  }
}
</script>