<template>
  <b-tabs class="mt-4">
    <b-tab title="General">
      <GeneralInformation />
    </b-tab>
    <b-tab title="Change Password">
      <ChangePassword />
    </b-tab>
  </b-tabs>
</template>

<script>
import GeneralInformation from "./generalInformation.vue";
import ChangePassword from "./changePassword.vue";
export default {
  components: { GeneralInformation, ChangePassword },
  name: "Profile",
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      debounce: null,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Profile | Content Management System Ebooks Gramedia.com";
      }
    },
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
