var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_vm.showPassword ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "inlineFormInputGroup",
      "placeholder": "new password",
      "state": _vm.statePassword(_vm.form.password),
      "min": "8"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "id": "inlineFormInputGroup",
      "placeholder": "new password",
      "state": _vm.statePassword(_vm.form.password),
      "min": "8"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('div', {
    staticClass: "input-group-text",
    on: {
      "click": _vm.toggleShow
    }
  }, [_c('i', {
    class: {
      'fa fa-eye': _vm.showPassword,
      'fa fa-eye-slash': !_vm.showPassword
    }
  })])])]), _vm.form.password && !_vm.statePassword(_vm.form.password) ? _c('small', {
    staticClass: "text-danger mt-1"
  }, [_vm._v("*minimum 8 character")]) : _vm._e(), _c('br'), _c('br'), _c('div', {
    staticClass: "input-group"
  }, [_vm.showPasswordConfirm ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.confirmPassword,
      expression: "form.confirmPassword"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "inlineFormInputGroup",
      "placeholder": "confirm password",
      "state": _vm.stateConfirmPassword(_vm.form.password, _vm.form.confirmPassword)
    },
    domProps: {
      "value": _vm.form.confirmPassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "confirmPassword", $event.target.value);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.confirmPassword,
      expression: "form.confirmPassword"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "id": "inlineFormInputGroup",
      "placeholder": "confirm password",
      "state": _vm.stateConfirmPassword(_vm.form.password, _vm.form.confirmPassword)
    },
    domProps: {
      "value": _vm.form.confirmPassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "confirmPassword", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('div', {
    staticClass: "input-group-text",
    on: {
      "click": _vm.toggleShowConfirm
    }
  }, [_c('i', {
    class: {
      'fa fa-eye': _vm.showPasswordConfirm,
      'fa fa-eye-slash': !_vm.showPasswordConfirm
    }
  })])])]), _vm.form.confirmPassword && !_vm.stateConfirmPassword(_vm.form.password, _vm.form.confirmPassword) ? _c('small', {
    staticClass: "text-danger mt-1"
  }, [_vm._v("*passwords do not match")]) : _vm._e(), _c('br'), _vm._v(" "), _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": "",
      "disabled": _vm.isDisabled
    }
  }, [_vm._v(" Change Password ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }