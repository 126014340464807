var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "4",
      "lg": "3"
    }
  }, [_c('img', {
    staticClass: "avatar img avatar-xl img-avatar rounded",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "src": require("../../assets/img/avatar.png"),
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('b-col', {
    staticClass: "text-center text-md-left"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_c('i', {
    staticClass: "fa fa-user"
  }), _vm._v(" Username : ")]), _vm._v(" " + _vm._s(this.username) + " ")]), _c('li', [_c('b-row', {
    staticClass: "p-3 flex-nowrap align-items-baseline justify-content-start"
  }, [_c('div', {
    staticClass: "font-weight-bold mr-1"
  }, [_c('i', {
    staticClass: "fa fa-envelope"
  }), _vm._v(" Email : ")]), !_vm.editMode ? _c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(this.email))]) : _c('span', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editedEmail,
      expression: "editedEmail"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "email"
    },
    domProps: {
      "value": _vm.editedEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.editedEmail = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "btn btn-primary mt-2",
    on: {
      "click": _vm.saveEmail
    }
  }, [_vm._v("Save")])]), _c('span', [_c('i', {
    class: ['fa', _vm.editMode ? 'fa-close' : 'fa-pencil', 'ml-2', 'cursor-pointer'],
    on: {
      "click": _vm.toggleEditMode
    }
  })])])], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }